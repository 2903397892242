//@ts-nocheck
import { ReactElement } from "react";
import { Entry } from "contentful";
import {
  ITextTopicFields,
  ITwoStackColumnFields,
  IMediaAssemblyFields,
} from "src/types/generated/contentful";
import mediaParser, { MediaParsed } from "../media/mediaParser";
import textTopicParser, { TextTopicParsed } from "../textTopic/textTopicParser";

export type StackColumnParsed = MediaParsed | TextTopicParsed;
export type BackgroundColorType = "white" | "light_blue" | "dark_blue";

export const backgroundColorPalette: {
  [key: string]: "white" | "lightBlue" | "darkBlue";
} = {
  white: "white",
  light_blue: "lightBlue",
  dark_blue: "darkBlue",
};

export type TwoStackColumnParsed = {
  firstColumn: StackColumnParsed;
  secondColumn: StackColumnParsed;
  backgroundColor: BackgroundColorType;
};

export type TwoStackColumnProps = {
  firstColumn: ReactElement;
  secondColumn: ReactElement;
  backgroundColor: BackgroundColorType;
};

export function stackColumnParser(
  data: Entry<IMediaAssemblyFields | ITextTopicFields>
): StackColumnParsed {
  if ("mediaReference" in data.fields) {
    return mediaParser(data as Entry<IMediaAssemblyFields>);
  }

  return textTopicParser(data as Entry<ITextTopicFields>);
}

export default function twoStackColumnParser({
  fields,
}: Entry<ITwoStackColumnFields>): TwoStackColumnParsed {
  return {
    firstColumn: stackColumnParser(fields.firstColumn),
    secondColumn: stackColumnParser(fields.secondColumn),
    backgroundColor: fields.backgroundColor,
  };
}
