import { Heading } from "@chakra-ui/react";
import Section from "@src/components/atoms/Section";
import { IFaq, IFaqEntryFields } from "@src/types/generated/contentful";
import React from "react";
import Entry from "./Entry";
import { Col, Grid, Inner, Wrapper } from "./styles";

type FaqProps = {
  header: string;
  entries: IFaq[];
  themeName?: string;
};

const FaqCard: React.FC<FaqProps> = (data) => {
  const { header, entries, themeName, ...rest } = data;
  return (
    <>
      <Section theme={themeName} {...rest}>
        <Wrapper {...rest}>
          <Heading as="h2" size="2xl" data-testid="faq-header">
            {header}
          </Heading>
          <Inner>
            <Grid>
              {entries?.map((entry, i) => {
                const e = entry?.fields as IFaqEntryFields;
                return (
                  <Col key={i} data-testid="faq-entry">
                    <Entry entry={e} />
                  </Col>
                );
              })}
            </Grid>
          </Inner>
        </Wrapper>
      </Section>
    </>
  );
};

export default FaqCard;
