import { IVideoTopic } from "@src/types/generated/contentful";
import { VideoJsonLd } from "next-seo";
import React from "react";

export type VideoObjectSchemaProps = {
  name?: string;
  description?: string;
  pageUrl?: string;
  embedUrl?: string;
  uploadDate?: string;
};

const VideoObjectSchema = (props: VideoObjectSchemaProps) => {
  const { name, description, pageUrl, embedUrl, uploadDate } = props;
  const ytVal = embedUrl?.split("embed/")[1];

  return (
    <>
      <VideoJsonLd
        id={`${pageUrl}/#video`}
        name={name}
        description={description}
        thumbnailUrl={`https://img.youtube.com/vi/${ytVal}/hqdefault.jpg`}
        uploadDate={uploadDate}
        isFamilyFriendly={true}
        isPartOf={{
          "@id": pageUrl || undefined,
        }}
        contentUrl={pageUrl}
        embedUrl={embedUrl}
      />
    </>
  );
};

export default VideoObjectSchema;
