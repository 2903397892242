import React from "react";
import { Asset, AssetFile } from "contentful";
import { ImageJsonLd } from "next-seo";
import { fixImagePath } from "@src/utils/dom";

export type ImageObjectProps = {
  image?: Asset;
  imageFile?: AssetFile;
  caption?: string;
  pageUrl: string;
};
const ImageObject = ({
  pageUrl,
  caption,
  image,
  imageFile,
}: ImageObjectProps) => {
  const imageFileI = imageFile || image?.fields?.file;
  const imageFileDetailsI =
    imageFile?.details || (image?.fields?.file?.details as any);

  const captionI = image?.fields?.title || caption;

  const imageObject = {
    "@type": "ImageObject",
    inLanguage: "en-US",
    "@id": `${pageUrl}/#primaryimage`,
    url: fixImagePath(imageFileI?.url?.toString()),
    contentUrl: fixImagePath(imageFileI?.url?.toString()),
    width: imageFileDetailsI?.width || 800,
    height: imageFileDetailsI?.height || 600,
    caption: captionI,
  };
  return (
    <>
      <script
        type="application/ld+json"
        dangerouslySetInnerHTML={{
          __html: `
      ${JSON.stringify(imageObject)}
      `,
        }}
      />
    </>
  );
};

export default ImageObject;
