import Typography from "src/components/atoms/Typography";
import { down, up } from "styled-breakpoints";
import styled from "styled-components";

export const Wrapper = styled.div`
  border-radius: 15px 15px 15px 15px;
  border-style: solid;
  border-width: 1px 1px 1px 1px;
  border-color: #00376415;
  box-shadow: 0px 5px 10px 0px rgb(0 55 100 / 15%);
  transition: background 0.3s, border 0.3s, border-radius 0.3s, box-shadow 0.3s;
  background: white;
  text-align: left;

  ${up("md")} {
    margin: 1em 1em 1em 1em;
    padding: 2em 2em 2em 2em;
    height: 100%;
  }

  ${down("sm")} {
    border-style: solid;
    border-width: 1px 1px 1px 1px;
    border-color: #00376415;
    box-shadow: 0px 2px 18px 0px rgb(0 55 100 / 27%);
    margin: 1em 0em 1em 0em;
    padding: 0.75em 0.75em 0.75em 0.75em;
  }
`;

export const Top = styled.div`
  display: flex;
  align-items: flex-start;
  justify-content: space-between;
  margin: 0em 0em 1.5em 0em;

  ${down("md")} {
    margin-bottom: 21px;
  }

  ${down("sm")} {
    padding: 12px 18px;
    margin: 0;
    cursor: pointer;
  }
`;

export const Question = styled(Typography).attrs({ variant: "h5" })`
  ${down("sm")} {
    margin: 0;
    font-weight: 700;
    line-height: 1;
    cursor: pointer;
    outline: none;
  }
`;

export const ToggleButton = styled.span`
  display: none;

  ${down("sm")} {
    display: block;
    font-size: 1rem;
    ${({ theme }) => `color: ${theme.palette.primary.light};`}
  }
`;

interface AnswerProps {
  $mobileVisible?: boolean;
}
export const Answer = styled(Typography).attrs<AnswerProps>({
  component: "div",
  variant: "body2",
})<AnswerProps>`
  ${down("sm")} {
    padding: 7px 15px;
    display: ${({ $mobileVisible }) => ($mobileVisible ? "block" : "none")};
  }

  > p {
    &:first-child {
      margin-top: 0;
    }

    &:last-child {
      margin-bottom: 0.9rem;
    }
  }
`;
