//@ts-nocheck
//TODO: remove ts-nocheck
import React, { ReactElement } from "react";
import { RichContentParsed } from "src/services/contentful/richContent/richContentParser";
import TextTopic from "src/components/molecules/TextTopic";
import { Wrapper, Inner } from "./styles";
import { IRichContentFields } from "src/types/generated/contentful";

const parseAlignment = (alignment: string): "left" | "center" | "right" => {
  switch (alignment) {
    case "Left":
      return "left";
    case "Right":
      return "right";
    default:
      return "center";
  }
};

export default function RichContent(data: IRichContentFields): ReactElement {
  const { richContent, theme, backgroundColor, alignment, header, ...rest } =
    data;
  //console.log("RichContent", data);
  return (
    <Wrapper backgroundColor={backgroundColor} {...rest}>
      <Inner>
        {header ? <h2>{header}</h2> : null}
        <TextTopic
          header={header}
          richContent={richContent}
          backgroundColor={backgroundColor}
          textAlignment={parseAlignment(alignment)}
        />
      </Inner>
    </Wrapper>
  );
}
