type SiteMdtadataFormat = {
  siteTitle: string;
  blogTitle: string;
  siteDescription: string;
  blogDescription: string;
  language: string;
  mainSiteUrl: string;
  blogUrl: string;
  socialBanner: string;
  twitter: string;
  facebook: string;
  youtube: string;
  linkedin: string;
};

//TODO: all of this needs to move into environment variables
export default {
  siteTitle: "Senior Healthcare Direct",
  blogTitle: "Bob’s Medicare Blog",
  siteDescription:
    "Senior Healthcare Direct, MedicareBob, licensed Medicare agents help you shop and save on Medicare Advantage, Supplement, and Part D plans.",
  blogDescription:
    "Senior Healthcare Direct, MedicareBob, licensed Medicare agents help you shop and save on Medicare Advantage, Supplement, and Part D plans.",
  language: "en-us",
  mainSiteUrl: "https://www.seniorhealthcaredirect.com",
  blogUrl: "https://www.seniorhealthcaredirect.com/blog",
  socialBanner: "/og-image.jpg",
  twitter: "https://twitter.com/Twitter",
  facebook: "https://facebook.com",
  youtube: "https://youtube.com",
  linkedin: "https://www.linkedin.com",
} as SiteMdtadataFormat;
