import React from "react";
import MediaBlock from "src/components/molecules/MediaBlock";
import TextTopic from "src/components/molecules/TextTopic";
import Layout from "src/components/Layout";

import {
  TwoStackColumnParsed,
  StackColumnParsed,
} from "src/services/contentful/twoStackColumn/twoStackColumnParser";

import { ITextTopicFields } from "src/types/generated/contentful";
import { MediaParsed } from "src/services/contentful/media/mediaParser";

import { Column, Inner, Wrapper } from "./styles";

const ColumnTypeToComponentMap = {
  textTopic: TextTopic,
  mediaAssembly: MediaBlock,
};

type AlignType = "left" | "center" | "right";

const getComponent = (
  column: StackColumnParsed
): React.FC<
  (ITextTopicFields | MediaParsed) & {
    align: AlignType;
  }
> => {
  if ("media" in column) {
    return ColumnTypeToComponentMap.mediaAssembly as React.FC<
      (ITextTopicFields | MediaParsed) & {
        align: AlignType;
      }
    >;
  }

  return ColumnTypeToComponentMap.textTopic as unknown as React.FC<
    (ITextTopicFields | MediaParsed) & {
      align: AlignType;
    }
  >;
};

const columnType = (column: StackColumnParsed): string => {
  if ("media" in column) {
    return "media";
  }
  return "text";
};

const TwoStackColumn: React.FC<TwoStackColumnParsed> = ({
  firstColumn,
  secondColumn,
  ...rest
}) => {
  const FirstComponent = getComponent(firstColumn);
  const SecondComponent = getComponent(secondColumn);

  return (
    <Wrapper {...rest}>
      <Layout>
        <Inner
          firstColumnType={columnType(firstColumn)}
          secondColumnType={columnType(secondColumn)}
        >
          <Column type={columnType(firstColumn)}>
            <FirstComponent {...firstColumn} {...rest} align="left" />
          </Column>
          <Column type={columnType(secondColumn)}>
            <SecondComponent {...secondColumn} {...rest} align="left" />
          </Column>
        </Inner>
      </Layout>
    </Wrapper>
  );
};

export default TwoStackColumn;
