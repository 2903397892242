export const scrollTo = (domId: string, offset = 0) => {
  const el = document.getElementById(domId);
  if (el) {
    const rect = el.getBoundingClientRect();
    const scrollTop = window.pageYOffset || document.documentElement.scrollTop;
    const top = rect.top + scrollTop - offset;
    window.scrollTo(0, top);
  }
};

export const fixImagePath = (path?: string): string => {
  if (path && path.toString().startsWith("//")) {
    return path.replace("//", "https://");
  }
  return path || "";
};
