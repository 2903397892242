import { NextSeo, VideoJsonLd } from "next-seo";
import { ReactElement } from "react";
import { Asset, AssetFields, AssetFile } from "contentful";

import { fixImagePath } from "@src/utils/dom";
import siteMetadata from "@src/data/siteMetadata";
import { ImagePrevSize } from "next-seo/lib/types";
import ImageObjectSchema from "./schemas/ImageObjectSchema";
import {
  IVideoTopic,
  IVideoTopicFields,
} from "@src/types/generated/contentful";
import VideoObjectSchema from "./schemas/VideoObjectSchema";

const robotsProps = {
  maxSnippet: -1,
  maxImagePreview: "large" as ImagePrevSize,
  maxVideoPreview: -1,
};

const getImagesUrls = (images: any, siteMetadata: any): string[] => {
  const imagesUrls: string[] = [];
  try {
    if (images && images.length > 0) {
      if (images[0]?.url) {
        imagesUrls.push(images.map((image: any) => fixImagePath(image.url)));
      }

      if (images[0].fields) {
        imagesUrls.push(
          images.map((image: any) => fixImagePath(image.fields?.file?.url))
        );
      }
    }
  } catch (e) {
    console.error(e);
    imagesUrls.push(siteMetadata?.socialBanner);
  }

  return imagesUrls;
};

export const MAIN_SITE_SEO = {
  title: siteMetadata.siteTitle,
  description: siteMetadata.siteDescription,
  openGraph: {
    type: "website",
    locale: siteMetadata.language,
    url: siteMetadata.mainSiteUrl,
    title: siteMetadata.siteTitle,
    description: siteMetadata.siteDescription,
    images: [
      {
        url: `https:${siteMetadata.mainSiteUrl}${siteMetadata.socialBanner}`,
        alt: siteMetadata.siteTitle,
        width: 1200,
        height: 600,
      },
    ],
  },
  twitter: {
    handle: siteMetadata.twitter,
    site: siteMetadata.twitter,
    cardType: "summary_large_image",
  },
};

export const BLOG_SEO = {
  title: siteMetadata.blogTitle,
  description: siteMetadata.blogDescription,
  openGraph: {
    type: "website",
    locale: siteMetadata.language,
    url: siteMetadata.blogUrl,
    title: siteMetadata.blogTitle,
    description: siteMetadata.blogDescription,
    images: [
      {
        url: `${siteMetadata.blogUrl}${siteMetadata.socialBanner}`,
        alt: siteMetadata.blogTitle,
        width: 1200,
        height: 600,
      },
    ],
  },
  twitter: {
    handle: siteMetadata.twitter,
    site: siteMetadata.twitter,
    cardType: "summary_large_image",
  },
};

const parseImageData = (images?: Asset[]): SEOImage[] | undefined => {
  return images
    ? images.map((image: Asset) => {
        const imagef = image?.fields as AssetFields;

        return {
          url: fixImagePath(imagef?.file?.url),
          width: imagef?.file?.details?.image?.width,
          height: imagef?.file?.details?.image?.height,
          alt: imagef?.title,
          type: imagef?.file?.contentType,
        };
      })
    : undefined;
};

const fixHomeCanonical = (url: string): string => {
  // fix home path
  if (url && url.endsWith(".com/home")) {
    return url.replace("/home", "/");
  }

  // fix path's for team members & blog posts
  return url;
};

type SEOImage = {
  url: string;
  width?: number;
  height?: number;
  alt?: string;
  type?: string;
};

type PageSeoProps = {
  title: string;
  description: string;
  url: string;
  images?: Asset[];
  video?: IVideoTopic;
  noindex: boolean;
  nofollow: boolean;
  datePublished: string;
  dateModified: string;
  authorName?: string;
  authorPicture?: string;
};
export function PageSeo({
  title,
  description,
  url,
  images,
  noindex,
  nofollow,
  video,
  datePublished,
  dateModified,
  authorName,
}: PageSeoProps): ReactElement {
  const vidFields = video?.fields as IVideoTopicFields;
  const imageUrls = getImagesUrls(images, siteMetadata);

  return (
    <>
      {images && images.length > 0 ? (
        <ImageObjectSchema image={images[0]} pageUrl={fixHomeCanonical(url)} />
      ) : null}

      {vidFields ? (
        <VideoObjectSchema
          name={vidFields?.id}
          description={vidFields?.description || ""}
          pageUrl={fixHomeCanonical(url)}
          embedUrl={vidFields?.youtubeUrl}
          uploadDate={vidFields?.uploadDate}
        />
      ) : null}

      <NextSeo
        nofollow={nofollow}
        noindex={noindex}
        robotsProps={robotsProps}
        title={title ? title : ""}
        //defaultTitle={`${siteMetadata.siteTitle}`}
        description={description}
        canonical={fixHomeCanonical(url)}
        openGraph={{
          url,
          title,
          type: "website",
          locale: siteMetadata.language,
          description,
          images: parseImageData(images),
        }}
        twitter={{
          handle: "@medicarebob",
          site: "@medicarebob",
          cardType: "summary_large_image",
        }}
      />
    </>
  );
}

type BlogSeoProps = {
  title: string;
  excerpt: string;
  date?: string;
  url: string;
  author?: string;
  authorPicture?: string;
  image?: Asset | AssetFile;
  video?: IVideoTopic;
  dateModified: string;
};
export function BlogSeo({
  title,
  excerpt,
  date,
  url,
  author,
  authorPicture = "",
  image,
  video,
  dateModified,
}: BlogSeoProps): ReactElement {
  const publishedAt = date ? new Date(date).toISOString() : "";
  const vidFields = video?.fields as IVideoTopicFields;
  const imageUrl =
    // @ts-ignore
    (image?.url ? image?.url : image?.fields?.file?.url) ||
    siteMetadata.socialBanner;
  const featuredImages = [
    {
      url: fixImagePath(imageUrl),
      alt: title,
    },
  ];

  return (
    <>
      {video ? (
        <VideoJsonLd
          name={vidFields?.id}
          description={vidFields?.description}
          contentUrl={vidFields?.youtubeUrl}
          uploadDate={vidFields?.uploadDate}
        />
      ) : null}
      {image ? (
        <ImageObjectSchema
          image={image as any}
          pageUrl={fixHomeCanonical(url)}
        />
      ) : null}
      <NextSeo
        title={`${title} – ${siteMetadata.blogTitle}`}
        description={excerpt}
        canonical={fixHomeCanonical(url)}
        defaultTitle={`${siteMetadata.blogTitle}`}
        robotsProps={robotsProps}
        openGraph={{
          type: "article",
          article: {
            publishedTime: publishedAt,
          },
          url,
          title,
          description: excerpt,
          images: featuredImages,
        }}
        additionalMetaTags={[
          {
            name: "twitter:image",
            content: fixImagePath(imageUrl),
          },
        ]}
      />
    </>
  );
}
