import styled from "styled-components";
import { down } from "styled-breakpoints";
import Typography from "src/components/atoms/Typography";
import { backgroundColorPalette } from "src/services/contentful/twoStackColumn/twoStackColumnParser";

interface WrapperProps {
  backgroundColor?: string;
}
export const Wrapper = styled.div<WrapperProps>`
  ${({ theme, backgroundColor }) =>
    backgroundColor &&
    `background-color: ${
      // eslint-disable-next-line
      theme.palette.common[backgroundColorPalette[backgroundColor]]
    };`}

  transition: background 0.3s, border 0.3s, border-radius 0.3s, box-shadow 0.3s;
  margin-top: 0%;
  margin-bottom: 0%;
  padding: 3em;

  ${down("sm")} {
    margin-top: 0px;
    margin-bottom: 0px;
    padding: 3rem 1rem 3rem 1rem;
  }
`;

export const Inner = styled.div`
  ${({ theme }) => `max-width: ${theme.size.desktopMaxWidth}px;`}
  margin: 0 auto;
  padding: 0% 10% 0% 10%;
  text-align: center;

  ${down("md")} {
    margin-top: 0px;
    margin-bottom: 0px;
    padding: 0rem 2rem 0rem 2rem;
  }

  ${down("sm")} {
    margin-top: 0px;
    margin-bottom: 0px;
    padding: 0px 0px 0px 0px;
  }
`;

export const Header = styled(Typography).attrs({ variant: "h6" })`
  margin-bottom: 21px;

  ${down("md")} {
    font-size: 15.36px;
  }
`;

export const Content = styled(Typography)`
  ${down("md")} {
    font-size: 12.6px;
    line-height: 1.753;
  }
`;
