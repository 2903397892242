import styled from 'styled-components'

export const VideoResponsive = styled.div`
  width: 100%;
  height: auto;
  display: block;
  object-fit: cover;
  overflow: hidden;
  padding-bottom: 56.25%;
  position: relative;
  height: 0;
`

export const VideoIframe = styled.iframe`
  border: 0;
  height: 100%;
  left: 0;
  position: absolute;
  top: 0;
  width: 100%;
`
