import Typography from "src/components/atoms/Typography";
import { down, up } from "styled-breakpoints";
import styled from "styled-components";

export const Wrapper = styled.div`
  padding: 89px 0 64px;

  ${down("sm")} {
    padding: 50px 0;
  }
`;

export const Inner = styled.div`
  ${({ theme }) => `max-width: ${theme.size.desktopMaxWidth}px;`}
  margin: 0 auto;

  ${down("md")} {
    max-width: 674px;
  }

  ${down("sm")} {
    max-width: none;
    margin-left: 24px;
    margin-right: 24px;
  }
`;

export const Header = styled(Typography).attrs({ variant: "h2" })`
  text-align: center;
  max-width: 100%;
  margin: 0px 0px 0px 0px;
  padding: 0em 0em 1em 0em;
  text-transform: capitalize;

  ${({ theme }) => `color: ${theme.palette.common.darkBlue};`}
`;

export const Grid = styled.div`
  ${up("md")} {
    display: flex;
    align-items: stretch;
    justify-content: center;
    flex-wrap: wrap;
    margin: 0 -11px;
  }
`;

export const Col = styled.div`
  margin-bottom: 12px;

  ${up("md")} {
    flex: none;
    width: 33.33%;
    padding: 0 11px;
    margin-bottom: 36.46px;
  }

  ${up("lg")} {
    margin-bottom: 48px;
  }
`;
