import React from "react";
import Image from "next/image";
import { MediaParsed } from "src/services/contentful/media/mediaParser";
import { VideoResponsive, VideoIframe } from "./styles";
import { IMediaAssemblyFields } from "src/types/generated/contentful";

export type MediaBlockProps = MediaParsed & {
  // eslint-disable-next-line react/no-unused-prop-types
  align: "left" | "center" | "right";
};

// youtube embed url: `https://www.youtube.com/embed/${embedId}`
const MediaBlock: React.FC<MediaBlockProps> = ({ media }: any) => {
  if ("image" in media && media.image.url) {
    return (
      <Image
        data-testid="Cover Image"
        src={media.image.url}
        alt={media.image.fileName}
        width={media.image.details?.image?.width}
        height={media.image.details?.image?.height}
        layout={
          media.image.details?.image?.width &&
          media.image.details?.image?.height
            ? "responsive"
            : "fill"
        }
      />
    );
  }
  if ("video" in media && media.video.url) {
    return (
      <VideoResponsive>
        <VideoIframe
          width="100%"
          height="100%"
          src={`${media.video.url}?enablejsapi=1`}
          frameBorder="0"
          allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture"
          allowFullScreen
          title="Embedded youtube"
        />
      </VideoResponsive>
    );
  }
  return null;
};

export default MediaBlock;
