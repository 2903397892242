import { AddIcon, MinusIcon } from "@chakra-ui/icons";
import {
  Accordion,
  AccordionButton,
  AccordionItem,
  AccordionPanel,
  Box,
  Flex,
  Heading,
} from "@chakra-ui/react";
import Section from "@src/components/atoms/Section/Section";
import { renderDocument } from "@src/components/atoms/TextBlock";
import { IFaq, IFaqEntryFields } from "@src/types/generated/contentful";
import React from "react";

type FaqProps = {
  header: string;
  entries: IFaq[];
  themeName?: string;
};

const FaqAccordion: React.FC<FaqProps> = (data) => {
  const { header, entries, themeName, ...rest } = data;
  return (
    <>
      <Section theme={themeName}>
        <Flex
          gap={4}
          flexDirection="column"
          justifyContent="flex-start"
          alignItems="center"
          {...rest}
        >
          <Heading textAlign="center" as="h2" size="2xl" data-testid="faq-header">
            {header}
          </Heading>

          <Box width="100%" maxW="900px">
            <Accordion>
              {entries?.map((entry, i) => {
                const e = entry?.fields as IFaqEntryFields;
                return (
                  <AccordionItem key={i} border="none" mb={1} data-testid="faq-entry">
                    {({ isExpanded }) => (
                      <>
                        <Heading as="h3" m={0} p={0} borderRadius={8}>
                          <AccordionButton
                            borderRadius={8}
                            backgroundColor={
                              themeName &&
                              themeName?.toLowerCase().indexOf("medium") >= 0
                                ? "brandSecondary.600"
                                : "brandAccent.100"
                            }
                          >
                            <Box as="span" flex="1" textAlign="left">
                              {e.question}
                            </Box>
                            {isExpanded ? (
                              <MinusIcon fontSize="12px" />
                            ) : (
                              <AddIcon fontSize="12px" />
                            )}
                          </AccordionButton>
                        </Heading>
                        <AccordionPanel textAlign="left" pb={4}>
                          {renderDocument(e?.answer, { renderAsText: true })}
                        </AccordionPanel>
                      </>
                    )}
                  </AccordionItem>
                );
              })}
            </Accordion>
          </Box>
        </Flex>
      </Section>
    </>
  );
};

export default FaqAccordion;
