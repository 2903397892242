import { IFaq } from "@src/types/generated/contentful";
import React from "react";
import FaqAccordion from "./Faq.Accordion";
import FaqCard from "./Faq.Card";

type FaqProps = {
  header: string;
  entries: IFaq[];
  layoutName: "card" | "accordion";
  themeName?: "light" | "medium";
};

const Faq: React.FC<FaqProps> = (data) => {
  const { header, entries, layoutName, ...rest } = data;

  const layout =
  layoutName && layoutName?.toLowerCase() === "accordion" ? (
    <FaqAccordion {...data} />
  ) : (
    <FaqCard {...data} />
  );


  return (
    <>
      {layout}
    </>
  );
};

export default Faq;
