import { faChevronDown, faChevronUp } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import React from "react";
import { renderDocument } from "src/components/atoms/TextBlock";

import { IFaqEntryFields } from "@src/types/generated/contentful";
import { Answer, Question, ToggleButton, Top, Wrapper } from "./styles";

type EntryProps = {
  entry: IFaqEntryFields;
};

const Entry: React.FC<EntryProps> = ({ entry, ...rest }) => {
  const [mobileExpanded, setMobileExpanded] = React.useState(true);
  return (
    <Wrapper {...rest}>
      <Top onClick={() => setMobileExpanded((current) => !current)}>
        <Question>{entry?.question}</Question>
        <ToggleButton>
          <FontAwesomeIcon
            icon={mobileExpanded ? faChevronUp : faChevronDown}
          />
        </ToggleButton>
      </Top>
      <Answer $mobileVisible={mobileExpanded}>
        {renderDocument(entry?.answer, { renderAsText: true })}
      </Answer>
    </Wrapper>
  );
};

export default Entry;
