import { down, up } from "styled-breakpoints";
import styled from "styled-components";

import {
  BackgroundColorType,
  backgroundColorPalette,
} from "src/services/contentful/twoStackColumn/twoStackColumnParser";

interface WrapperProps {
  backgroundColor: BackgroundColorType;
}

interface ColumnProps {
  type?: string;
}
interface RowInnerProps {
  firstColumnType?: string;
  secondColumnType?: string;
}

export const Inner = styled.div<RowInnerProps>`
  margin: -1rem -2rem;
  display: flex;
  flex-direction: row;
  ${({ firstColumnType, secondColumnType }) =>
    `align-items: ${
      firstColumnType === "text" && secondColumnType === "text"
        ? "flex-start"
        : "center"
    };`}

  ${down("sm")} {
    ${({ firstColumnType, secondColumnType }) =>
      `flex-direction: ${
        firstColumnType !== "media" && secondColumnType === "media"
          ? "column-reverse"
          : "column"
      };`}
  }
`;
export const Column = styled.div<ColumnProps>`
  flex: 1;
  padding: 1rem 2rem;

  ${down("sm")} {
    width: 100%;
    flex: none;
  }

  ${up("lg")} {
    ${({ type }) =>
      type === "media" &&
      `
      width: 60%;
      flex: none;
    `}
  }
`;

export const Wrapper = styled.div<WrapperProps>`
  padding: 3em 0;

  ${({ theme, backgroundColor }) =>
    backgroundColor &&
    `background-color: ${
      // eslint-disable-next-line
      theme.palette.common[backgroundColorPalette[backgroundColor]]
    };`}

  ${down("sm")} {
    padding: 3rem 0 !important;
  }
`;
